<div class="d-flex align-items-center profile">
  <button #button class="p-0 btn" (click)="open(content)">
    <fa-icon class="d-none d-lg-inline" [icon]="['fas', 'user']"></fa-icon>
    <fa-icon class="d-lg-none" [icon]="['fas', 'user']" [transform]="'grow-4'"></fa-icon>
  </button>
</div>

<ng-template #content let-offcanvas>
  <div class="profile-menu-wrapper">
    <div class="offcanvas-header">
      <div class="d-flex flex-column">
        <div class="profile-label">{{ 'Name' | translate }}</div>
        <div>{{ (user$ | async)?.firstName }} {{ (user$ | async)?.lastName }}</div>
      </div>

      <div class="d-flex flex-column">
        <div class="profile-label">{{ 'Email' | translate }}</div>
        <div>{{ (user$ | async)?.email }}</div>
      </div>
    </div>
    <div class="offcanvas-body main">
      <div class="h-100 d-flex align-items-start flex-column" routerLinkActive="active">
        <a *ngFor="let item of profileMenu.items" class="profile-menu-item" [routerLink]="item.url">
          <img [src]="item.icon" alt="" />
          {{ item.label }}
        </a>
      </div>
    </div>
    <hr />
    <div class="offcanvas-body main">
      <a class="logout" (click)="logOut()">
        <fa-icon class="ms-1 me-3" [icon]="['fas', 'arrow-right-from-bracket']" [size]="'lg'"></fa-icon>
        <span>{{ 'Log out' | translate }}</span>
      </a>
    </div>
    <div class="widgets">
      <hr *ngIf="!!intelPremiumRewardsSidebarData || !!ssgEmeaSidebarData || !!valenciaSummaryData" />
      <app-intel-premium-rewards-widget
        [intelPremiumRewardsSidebarData]="intelPremiumRewardsSidebarData"
      ></app-intel-premium-rewards-widget>
      <app-ssg-emea-widget [ssgEmeaSidebarData]="ssgEmeaSidebarData"></app-ssg-emea-widget>
      <app-valencia-summary-widget [valenciaSummaryData]="valenciaSummaryData"></app-valencia-summary-widget>
      <ng-container *ngIf="showRewardCatalogWidgets">
        <hr [ngClass]="{ 'mt-0': !!intelPremiumRewardsSidebarData || !!ssgEmeaSidebarData }" />
        <app-account-summary-widget></app-account-summary-widget>
        <hr />
        <app-cart-widget></app-cart-widget>
      </ng-container>
      <ng-container *ngIf="showMastercarWidgets">
        <hr [ngClass]="{ 'mt-0': !!intelPremiumRewardsSidebarData || !!ssgEmeaSidebarData }" />
        <app-mastercard-account-information></app-mastercard-account-information>
      </ng-container>
    </div>
  </div>
</ng-template>
