import { CommonModule } from '@angular/common';
import { CUSTOM_ELEMENTS_SCHEMA, NgModule } from '@angular/core';
import { ReactiveFormsModule } from '@angular/forms';
import { RouterModule } from '@angular/router';
import { FaIconLibrary, FontAwesomeModule } from '@fortawesome/angular-fontawesome';
import { faXmark as falXmark } from '@fortawesome/pro-light-svg-icons';
import {
  faBell,
  faChevronRight,
  faChevronUp,
  faExclamationTriangle,
  faMinus,
  faPlus,
  faCheck,
  faXmark as farXmark,
} from '@fortawesome/pro-regular-svg-icons';
import {
  BannerModule,
  BreadcrumbsModule,
  FormModule,
  GenericCarouselModule,
  GenericListModule,
  PipesModule,
} from '@motivforce/mx-library-angular';
import { NgbCarouselModule, NgbDatepickerModule, NgbDropdownModule } from '@ng-bootstrap/ng-bootstrap';
import { TranslateModule } from '@ngx-translate/core';
import { NgxSpinnerModule } from 'ngx-spinner';

import { AccentRowComponent } from './components/accent-row/accent-row.component';
import { AccountSummaryWidgetComponent } from './components/account-summary-widget/account-summary-widget.component';
import { ActionBoxComponent } from './components/action-box/action-box.component';
import { BackToLinkComponent } from './components/back-to-link/back-to-link.component';
import { BackToTopButtonComponent } from './components/back-to-top-button/back-to-top-button.component';
import { CarouselSliderComponent } from './components/carousel-slider/carousel-slider.component';
import { CartWidgetComponent } from './components/cart-widget/cart-widget.component';
import { CustomClaimedInvoicesListComponent } from './components/custom-claimed-invoices-list/custom-claimed-invoices-list.component';
import { CustomIsgSubmissionListComponent } from './components/custom-isg-submission-list/custom-isg-submission-list.component';
import { CustomListComponent } from './components/custom-list/custom-list.component';
import { CustomProductListComponent } from './components/custom-product-list/custom-product-list.component';
import { CustomProductPointListComponent } from './components/custom-product-point-list/custom-product-point-list.component';
import { CustomSalesClaimingListComponent } from './components/custom-sales-claiming-list/custom-sales-claiming-list.component';
import { CustomSelectFormControlComponent } from './components/custom-select-form-control/custom-select-form-control.component';
import { CustomTransactionsListComponent } from './components/custom-transactions-list/custom-transactions-list.component';
import { DefaultMainLayoutComponent } from './components/default-main-layout/default-main-layout.component';
import { DynamicCheckboxGroupComponent } from './components/dynamic-checkbox-group/dynamic-checkbox-group.component';
import { ImageSliderPuzzleComponent } from './components/image-slider-puzzle/image-slider-puzzle.component';
import { IntelPremiumRewardsWidgetComponent } from './components/intel-premium-rewards-widget/intel-premium-rewards-widget.component';
import { LoadingIndicatorComponent } from './components/loading-indicator/loading-indicator.component';
import { LogoComponent } from './components/logo/logo.component';
import { MastercardAccountInformationComponent } from './components/mastercard-account-information/mastercard-account-information.component';
import { ModalOpenPaddingWrapperComponent } from './components/modal-open-padding-wrapper/modal-open-padding-wrapper.component';
import { PcsdToggleButtonGroupComponent } from './components/pcsd-toggle-button-group/pcsd-toggle-button-group.component';
import { PromotionAnimatedButtonComponent } from './components/promotion-animated-button/promotion-animated-button.component';
import { PromotionBoxComponent } from './components/promotion-box/promotion-box.component';
import { SsgEmeaWidgetComponent } from './components/ssg-emea-widget/ssg-emea-widget.component';
import { ValenciaSummaryWidgetComponent } from './components/valencia-summary-widget/valencia-summary-widget.component';
import { WidgetMainLayoutComponent } from './components/widget-main-layout/widget-main-layout.component';
import { MinutesSecondsPipe } from './pipes/minutes-seconds.pipe';
import { ShowCreditsIconPipe } from './pipes/show-credits-icon.pipe';
import { ShowPointsIconPipe } from './pipes/show-points-icon.pipe';
import { ShowRevenueIconPipe } from './pipes/show-revenue-icon.pipe';

@NgModule({
  declarations: [
    LogoComponent,
    LoadingIndicatorComponent,
    AccountSummaryWidgetComponent,
    MinutesSecondsPipe,
    ModalOpenPaddingWrapperComponent,
    ImageSliderPuzzleComponent,
    PromotionAnimatedButtonComponent,
    DefaultMainLayoutComponent,
    WidgetMainLayoutComponent,
    CarouselSliderComponent,
    PromotionBoxComponent,
    ActionBoxComponent,
    CartWidgetComponent,
    CustomListComponent,
    CustomProductListComponent,
    AccentRowComponent,
    BackToLinkComponent,
    CustomSelectFormControlComponent,
    BackToTopButtonComponent,
    CustomSalesClaimingListComponent,
    ShowRevenueIconPipe,
    ShowPointsIconPipe,
    ShowCreditsIconPipe,
    CustomTransactionsListComponent,
    PcsdToggleButtonGroupComponent,
    DynamicCheckboxGroupComponent,
    CustomIsgSubmissionListComponent,
    CustomProductPointListComponent,
    IntelPremiumRewardsWidgetComponent,
    SsgEmeaWidgetComponent,
    CustomClaimedInvoicesListComponent,
    MastercardAccountInformationComponent,
    ValenciaSummaryWidgetComponent,
  ],
  imports: [
    CommonModule,
    TranslateModule,
    RouterModule,
    FontAwesomeModule,
    ReactiveFormsModule,
    NgbDatepickerModule,
    NgxSpinnerModule,
    NgbDropdownModule,
    GenericCarouselModule,
    BannerModule,
    TranslateModule,
    BreadcrumbsModule,
    GenericListModule,
    NgbCarouselModule,
    FormModule,
    PipesModule,
  ],
  exports: [
    LogoComponent,
    LoadingIndicatorComponent,
    AccountSummaryWidgetComponent,
    MinutesSecondsPipe,
    ModalOpenPaddingWrapperComponent,
    ImageSliderPuzzleComponent,
    PromotionAnimatedButtonComponent,
    DefaultMainLayoutComponent,
    CarouselSliderComponent,
    CartWidgetComponent,
    PromotionBoxComponent,
    ActionBoxComponent,
    CartWidgetComponent,
    CustomListComponent,
    CustomProductListComponent,
    AccentRowComponent,
    BackToLinkComponent,
    CustomSelectFormControlComponent,
    BackToTopButtonComponent,
    CustomSalesClaimingListComponent,
    ShowRevenueIconPipe,
    ShowPointsIconPipe,
    ShowCreditsIconPipe,
    CustomTransactionsListComponent,
    PcsdToggleButtonGroupComponent,
    DynamicCheckboxGroupComponent,
    CustomIsgSubmissionListComponent,
    CustomProductPointListComponent,
    IntelPremiumRewardsWidgetComponent,
    SsgEmeaWidgetComponent,
    CustomClaimedInvoicesListComponent,
    MastercardAccountInformationComponent,
    ValenciaSummaryWidgetComponent,
  ],
  schemas: [CUSTOM_ELEMENTS_SCHEMA],
})
export class SharedModule {
  constructor(private iconlibrary: FaIconLibrary) {
    this.iconlibrary.addIcons(
      faBell,
      faChevronRight,
      faMinus,
      faPlus,
      falXmark,
      faChevronUp,
      faExclamationTriangle,
      faCheck,
      farXmark
    );
  }
}
