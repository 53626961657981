import { Injectable } from '@angular/core';
import {
  AccountSummary,
  ApiRestService,
  RedeemedReward,
  ReferenceData,
  SearchResult,
  BaseUser,
} from '@motivforce/mx-library-angular';
import { Observable, map } from 'rxjs';
import { environment } from 'src/environments/environment';

import { BusinessRuleMessage } from '../../model/core/business-rule-message';
import { CommunicationsPreference } from '../../model/core/communications-preference';
import { CompanyClaimedInvoiceResult } from '../../model/core/company-claimed-invoice-result';
import { InvoiceSearch } from '../../model/core/invoice-search';
import { Profile } from '../../model/core/profile';
import { RedemptionSearch } from '../../model/core/redemption-search';
import { RewardCollegue } from '../../model/core/reward-colleague';
import { SetPassword } from '../../model/core/set-password';
import { TaxRequirements } from '../../model/core/tax-requirements';
import { Transaction } from '../../model/core/transaction';
import { TransactionSearch } from '../../model/core/transaction-search';
import { TransferPoints } from '../../model/core/transfer-points';
import { TransferTransactionSearch } from '../../model/core/transfer-transaction-search';
import { LeapPerformanceSummary } from '../../model/leap-performance-summary';
import { LenovoLeapProfile } from '../../model/lenovo/lenovo-leap-profile';
import { LenovoLeapSalaryBand } from '../../model/lenovo/lenovo-leap-salary-band';
import { SpinToWin } from '../../model/promotion/spin-to-win';

@Injectable({
  providedIn: 'root',
})
export class UserRestService {
  constructor(private apiRest: ApiRestService) {}

  getAccountSummary(): Observable<AccountSummary> {
    return this.apiRest.get<AccountSummary>(`${environment.api.core.baseUrl}/members/users/account-summary`);
  }

  getYearAccountSummaries(): Observable<AccountSummary[]> {
    return this.apiRest.get<AccountSummary[]>(`${environment.api.core.baseUrl}/members/users/points/year-summaries`);
  }

  getLeapPerformanceSummary(): Observable<LeapPerformanceSummary> {
    return this.apiRest.get<LeapPerformanceSummary>(
      `${environment.api.core.baseUrl}/members/clients/lenovo/leap/users/leap-performance`
    );
  }

  getProfile(): Observable<Profile> {
    return this.apiRest.get<Profile>(`${environment.api.core.baseUrl}/members/users/profile`);
  }

  getLenovoLeapProfile(): Observable<LenovoLeapProfile> {
    return this.apiRest.get<LenovoLeapProfile>(
      `${environment.api.core.baseUrl}/members/clients/lenovo/leap/users/profile`
    );
  }

  updateLenovoLeapProfile(leapProfile: LenovoLeapProfile): Observable<LenovoLeapProfile> {
    return this.apiRest.put<LenovoLeapProfile>(
      `${environment.api.core.baseUrl}/members/clients/lenovo/leap/users/profile`,
      leapProfile
    );
  }

  searchTransactions(searchCriteria: TransactionSearch): Observable<SearchResult<Transaction>> {
    return this.apiRest.post<SearchResult<Transaction>>(
      `${environment.api.core.baseUrl}/members/clients/lenovo/leap/users/account-statement`,
      searchCriteria
    );
  }

  getTransactionTypes(): Observable<ReferenceData[]> {
    return this.apiRest.get<ReferenceData[]>(`${environment.api.core.baseUrl}/members/transactions/types`);
  }

  searchPromotions(searchCriteria: TransactionSearch): Observable<SearchResult<Transaction>> {
    return this.apiRest.post<SearchResult<Transaction>>(
      `${environment.api.core.baseUrl}/members/users/promotion-transactions`,
      searchCriteria
    );
  }

  searchRedeemedRewards(searchCriteria: RedemptionSearch): Observable<SearchResult<RedeemedReward>> {
    return this.apiRest.post<SearchResult<RedeemedReward>>(
      `${environment.api.core.baseUrl}/members/redemptions/search`,
      searchCriteria
    );
  }

  getCommunicationPreferences(): Observable<any> {
    return this.apiRest.get(
      `${environment.api.core.baseUrl}/members/clients/lenovoleap/profile/communications-preferences`
    );
  }

  updateCommunicationPreferences(form: CommunicationsPreference): Observable<any> {
    return this.apiRest.post(`${environment.api.core.baseUrl}/members/users/profile/send-email`, form);
  }

  acceptTermsConditions(): Observable<any> {
    return this.apiRest.post(`${environment.api.core.baseUrl}/members/users/terms-and-conditions/accept`, null);
  }

  updateVenue(venueId: number): Observable<void> {
    return this.apiRest.put<void>(`${environment.api.core.baseUrl}/members/clients/amber/userdetails/venue`, {
      venueId,
    });
  }

  setPassword(password: SetPassword) {
    return this.apiRest.post(`${environment.api.core.baseUrl}/members/users/set-password`, password);
  }

  createCheckoutSession(form: any): Observable<any> {
    return this.apiRest.post(`${environment.api.core.baseUrl}/members/payments/create-checkout-session`, form);
  }

  rewardColleague(reward: RewardCollegue): Observable<any> {
    return this.apiRest.post(`${environment.api.core.baseUrl}/members/clients/lenovo/users/reward-colleague`, reward);
  }

  getPrcBalance(): Observable<any> {
    return this.apiRest.get(`${environment.api.core.baseUrl}/members/clients/lenovo/leap/companies/balance`);
  }

  getCompanyUsers(activeOnly: boolean): Observable<BaseUser[]> {
    return this.apiRest.get<BaseUser[]>(
      `${environment.api.core.baseUrl}/members/clients/lenovo/leap/companies/users/${activeOnly}`
    );
  }

  transferPoints(form: TransferPoints): Observable<any> {
    return this.apiRest.post(`${environment.api.core.baseUrl}/members/clients/lenovo/leap/companies/transfers`, form);
  }

  searchTransfers(search: TransferTransactionSearch): Observable<SearchResult<Transaction>> {
    return this.apiRest.post<SearchResult<Transaction>>(
      `${environment.api.core.baseUrl}/members/clients/lenovo/leap/companies/transfers/search`,
      search
    );
  }

  searchInvoices(search: InvoiceSearch): Observable<SearchResult<CompanyClaimedInvoiceResult>> {
    return this.apiRest.post<SearchResult<CompanyClaimedInvoiceResult>>(
      `${environment.api.core.baseUrl}/members/clients/lenovo/leap/companies/invoices/search`,
      search
    );
  }

  downloadInvoices(
    search: InvoiceSearch
  ): Observable<{ content: Blob | null; fileName: string | null; contentType: string | null }> {
    const filteredSearch = Object.fromEntries(Object.entries(search).filter(([, v]) => v != null)) as any;
    const params = new URLSearchParams(filteredSearch).toString();
    return this.apiRest
      .download(`${environment.api.core.baseUrl}/members/clients/lenovo/leap/companies/invoices/download?${params}`)
      .pipe(
        map((response) => ({
          content: response.body,
          fileName: response.headers.get('Filename'),
          contentType: response.headers.get('Content-Type'),
        }))
      );
  }

  searchAvailableEmails(email: string): Observable<ReferenceData[]> {
    return this.apiRest.post<ReferenceData[]>(`${environment.api.core.baseUrl}/members/users/company-users/search`, {
      email,
    });
  }

  getBusinessRules(): Observable<BusinessRuleMessage[]> {
    return this.apiRest.get(`${environment.api.core.baseUrl}/members/clients/lenovo/leap/users/business-rules`);
  }

  getTaxRequirements(): Observable<TaxRequirements> {
    return this.apiRest.get(`${environment.api.core.baseUrl}/members/clients/lenovo/leap/users/tax-requirements`);
  }

  getSalaryBands(): Observable<LenovoLeapSalaryBand[]> {
    return this.apiRest.get(
      `${environment.api.core.baseUrl}/members/clients/lenovo/leap/users/profile/dmr-salary-bands`
    );
  }

  getDmrCountries(): Observable<string[]> {
    return this.apiRest.get(`${environment.api.core.baseUrl}/members/clients/lenovo/leap/users/profile/dmr-countries`);
  }

  getSpinToWin(): Observable<SpinToWin> {
    return this.apiRest.get<SpinToWin>(`${environment.api.core.baseUrl}/members/clients/lenovo/leap/games/spin-to-win`);
  }
}
